var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100"
  }, [_c('v-draggable', {
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.breakpoint.xs,
      "list": _vm.location._information,
      "handle": ".handle"
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('autoSave');
      }
    }
  }, _vm._l(_vm.location._information, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('v-card', {
      staticClass: "mb-4",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-card-title', [!_vm.breakpoint.xs ? _c('vx-btn', {
      staticClass: "handle",
      attrs: {
        "icon": ""
      }
    }, [_c('vx-icon', [_vm._v("mdi-drag")])], 1) : _vm._e(), _vm._v(" " + _vm._s(item.title) + " "), _c('v-spacer'), _c('vx-btn', {
      attrs: {
        "icon": "",
        "disabled": index === 0
      },
      on: {
        "click": function click($event) {
          return _vm.moveUp(index);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-arrow-up")])], 1), _c('vx-btn', {
      attrs: {
        "icon": "",
        "disabled": index === _vm.information.length - 1
      },
      on: {
        "click": function click($event) {
          return _vm.moveDown(index);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-arrow-down")])], 1)], 1), _c('v-card-subtitle', [_vm._v(" " + _vm._s(item.description) + " ")]), _c('v-card-text', [_c('vx-input', {
      attrs: {
        "type": "wysiwyg"
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1)], 1)], 1);
  }), 0), _vm._m(0)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._v(" Think we should add another field here? Let us know at "), _c('a', {
    attrs: {
      "href": "mailto:support@tutti-space.com"
    }
  }, [_vm._v("support@tutti-space.com")])]);
}]

export { render, staticRenderFns }