<template>
  <v-form ref="form" lazy-validation>
    <v-card v-if="created">
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn width="100" outlined color="light" @click="preview = true">Preview</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, true)">
          Save & close
        </vx-btn>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-tabs v-model="tab" :show-arrows="$vuetify.breakpoint.xs">
          <v-tab>Basic</v-tab>
          <v-tab>Price</v-tab>
          <v-tab>Photos</v-tab>
          <v-tab>Availability</v-tab>
          <v-tab>General</v-tab>
          <!-- <v-tab>Covid</v-tab> -->
          <v-tab>Created Here</v-tab>
          <v-tab>More info / stories</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <BasicInfo :space="space" :accounts="accounts" />
          </v-tab-item>

          <v-tab-item>
            <PriceInfo :space="space" venue-type="location" />
          </v-tab-item>

          <v-tab-item>
            <Photo :space="space" venue-type="location" />
          </v-tab-item>

          <v-tab-item>
            <Availability :space="space" />
          </v-tab-item>

          <v-tab-item>
            <GeneralInfo :space="space" :features="locationFeatures" />
          </v-tab-item>

          <!-- <v-tab-item>
            <CovidInfo :space="space" :feature="covidFeature" />
          </v-tab-item> -->

          <v-tab-item>
            <CreatedHere :venue="space" venue-type="location" />
          </v-tab-item>

          <v-tab-item>
            <LocationStories :location="space" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <vx-dialog v-model="preview" :title="space._url" height="90vh" width="90%">
      <iframe :src="space._url" style="width: 100%; height: 75vh"> </iframe>
    </vx-dialog>
  </v-form>
</template>

<script>
import GeneralInfo from './general';
//import CovidInfo from './covid';
import Availability from './availability';
import BasicInfo from './basic';
import PriceInfo from '@admin/views/venue/edit/components/price.vue';
import Photo from '@admin/views/venue/edit/components/photo.vue';
import LocationStories from './locationStories';
import CreatedHere from './createdHere';
import { LocationService, AccountService } from '@tutti/services';
import { Location } from '@tutti/models';
import { FeatureMixin } from '@tutti/mixins';

export default {
  components: {
    BasicInfo,
    Availability,
    GeneralInfo,
    // CovidInfo,
    PriceInfo,
    CreatedHere,
    LocationStories,
    Photo,
  },
  mixins: [FeatureMixin],

  data: () => ({
    tab: 0,
    space: new Location(),
    locations: [],
    accounts: [],
    loading: false,
    created: false,
    preview: false,
    covidFeature: {},
  }),

  async created() {
    this.tab = this.$route?.params?.tab || 0;

    await this.getUser();
    //await this.getAccounts(); // Profile can't be change, So that this call commented

    this.$setFeatures({
      locationFeatures: true,
    });

    this.covidFeature = this.getFeatureBySlug('precautions_taken');
    this.space.initAttributes(this.features);

    this.created = true;
  },

  methods: {
    async getLocations() {
      const response = await LocationService.getByAccountId(this.space.accountId._id, {
        sort: 'name',
        sortBy: 'asc',
      });

      if (response) {
        this.locations = response.data;
      }
    },

    async getAccounts() {
      const response = await AccountService.getAll({
        filter: { userType: 'host' },
      });

      if (response) {
        this.accounts = response.data.data;
      }
    },

    async getUser() {
      const response = await LocationService.getByIdAdmin(this.$route.params.id);
      if (response) {
        this.space = new Location(response.data);
        this.space._fromAdmin = true;
        //this.space.covid = {};
        this.space._url = `${this.artistURL}/${this.space.accountId.slug}/${this.space.slug}`;
        this.space.initAttributes(this.features);
        this.$setTitle(this.space.name);
      }
    },

    async save(previous) {
      this.loading = true;

      const space = this.space.get();

      const response = await LocationService.patch(space);
      if (response && previous) {
        this.$previous();
      }

      this.loading = false;
    },
  },
};
</script>
