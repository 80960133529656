<template>
  <div>
    <SpaceFeatures :attributes="space._attributes" :features="features" />
  </div>
</template>

<script>
import { PRICE_MODELS } from '@tutti/constants';
import SpaceFeatures from './SpaceFeatures';

export default {
  components: {
    SpaceFeatures,
  },
  props: {
    space: { type: Object, required: true },
    features: { type: Array, required: true },
  },
  data() {
    return {
      PRICE_MODELS,
    };
  },
};
</script>
