<template>
  <div>
    <v-form lazy-validation>
      <v-row>
        <v-col cols="6" sm="6" md="6">
          <vx-input
            v-model="postcode"
            type="text"
            label="Enter postcode"
            :disabled="hasLocation"
            name="Postcode"
            placeholder="ex. ID1 1QD"
            append-outer-icon="mdi-magnify"
            required
            @change="setLocation()"
            @click:append-outer="getLocations()"
          />

          <vx-input
            v-model="udprn"
            type="select"
            :label="
              locations.length
                ? `Please select your address from ${locations.length} locations`
                : 'Please select your address'
            "
            name="Location"
            :items="locations"
            item-value="udprn"
            required
            @change="setAddress"
          />
          <vx-input
            v-model="location.radius"
            type="number"
            label="Enter radius(In meter)"
            name="radius"
            placeholder="ex. 200"
            required
          />
        </v-col>

        <v-col v-if="mounted" cols="6" sm="6" md="6">
          <what3words-autosuggest
            v-if="mounted"
            id="autosuggest"
            class="w-100 mb-6"
            :value="words"
            clip-to-country="GB"
            placeholder="daring.lion.race"
            @change="checkWhat3Words($event)"
          />

          <vx-input
            v-model="nearestPlace"
            type="text"
            readonly
            label="Nearest place"
            :disabled="hasLocation"
            name="Postcode"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { LocationService, MapService, ScriptService } from '@tutti/services';
import { Location } from '@tutti/models';

export default {
  name: 'SpaceBasicInfo',
  props: {
    location: { type: Object, required: true },
    locationId: { type: String },
    hasLocation: { type: Boolean, default: false },
  },
  data() {
    return {
      valid: true,
      mounted: false,
      loading: {
        getLocations: false,
      },
      currentLocation: this.locationId ? new Location() : this.location,
      postcode: this.location.address?.postcode,
      udprn: this.location.address?.udprn,
      locations: [],
      needDistance: !(this.location.what3Words?.words && this.location.address?.postcode),
      words: this.location.what3Words?.words,
      nearestPlace: this.location.what3Words?.nearestPlace,
    };
  },
  async mounted() {
    if (this.udprn) {
      this.getLocations(this.udprn);
    }

    if (!window.what3words) {
      await this.$loadScript(
        `https://assets.what3words.com/sdk/v3.1/what3words.js?key=${process.env.VUE_APP_WHAT3WORDS_API_KEY}`
      );
    }

    this.mounted = true;
  },
  updated() {
    if (this.mounted) {
      const element = document.getElementById('autosuggest');

      if (element.getAttribute('select') !== 'true') {
        element.addEventListener('select', value => {
          const attributes = value.target;
          attributes.setAttribute('select', 'true');
          if (this.words !== value.detail) {
            this.words = value.detail;
            this.setWhat3Words(this.words);
          }
        });
      }
    }
  },
  methods: {
    async checkDistance() {
      await ScriptService.loadMap();
      if (this.needDistance) {
        let distance = MapService.calculateDistance(this.location.what3Words, this.location.address);
        distance = this.$options?.filters?.distance(distance);
        if (parseFloat(distance) > 0.5) {
          await this.$warn({ title: 'DISTANCE', count: distance });
        }
      }
      this.needDistance = true;
    },
    checkWhat3Words($event) {
      if (!$event.detail) {
        this.location.what3Words = {};
      }
    },
    setLocation() {
      if (this.postcode) {
        this.setAddress(this.udprn);
      } else {
        this.location.address = {};
        this.locations = [];
      }
    },
    setAddress(udprn) {
      const location = this.locations.find(location => location.udprn == udprn);
      if (location) {
        this.location.setAddress(location);
        this.checkDistance();
      }
    },
    async setWhat3Words(selectedWord) {
      this.nearestPlace = '';
      if (selectedWord) {
        const res = await LocationService.get3WordsAddress({
          word: selectedWord,
          needSuggestion: false,
        });
        if (res.data) {
          this.location.set3WordsAddress(res.data);
          this.nearestPlace = this.location.what3Words.nearestPlace;
          this.checkDistance();
        }
      }
    },
    getLocations() {
      if (this.postcode) {
        this.loading.getLocations = true;

        fetch(
          `${process.env.VUE_APP_POSTCODE_API_URL}/postcodes/${this.postcode}?api_key=${process.env.VUE_APP_POSTCODE_API_KEY}`
        )
          .then(res => res.json())
          .then(res => {
            if (res.code == 2000) {
              this.locations = res.result.map(item => {
                item.text = [item.line_1, item.line_2, item.district].filter(x => !!x).join(', ');
                return item;
              });

              if (!this.udprn && this.locations.length) {
                this.udprn = this.locations[0].udprn;
                this.setAddress(this.udprn);
              }
            } else {
              this.$bus.$emit('error', res.message);
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => (this.loading.getLocations = false));
      }
    },
  },
};
</script>
