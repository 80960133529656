var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ContentList', {
    attrs: {
      "options": _vm.options
    }
  }, [_c('v-card-title', [_vm._v(" Created here ")]), _c('v-card-text', [_vm._v(" The purpose of this section is to showcase how people have used your space in the past, so people get a sense of what is possible. "), _c('b'), _vm._v(" Please share the public links of any content that was created here. This can include: YouTube, Soundcloud, Flickr, Vimeo, and any other links you wish to share. ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }