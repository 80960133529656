var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "10"
    }
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('b', [_vm._v("My space is available on...")]), _vm.breakpoint.xs ? _c('br') : _vm._e(), _vm._v("(Click a day to make it available) "), _c('vx-input', {
    staticClass: "mt-4",
    attrs: {
      "type": "checkbox",
      "label": "We’re open 24/7"
    },
    on: {
      "change": function change($event) {
        return _vm.space.setAvailabilities24x7();
      }
    },
    model: {
      value: _vm.space._twenty4by7,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "_twenty4by7", $$v);
      },
      expression: "space._twenty4by7"
    }
  })], 1), _vm._l(_vm.space.availabilities, function (availability, index) {
    return _c('v-row', {
      key: index
    }, [_vm.breakpoint.smAndDown ? _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-divider')], 1) : _vm._e(), _c('v-col', {
      attrs: {
        "cols": "12",
        "sm": "4",
        "md": "3"
      }
    }, [_c('vx-btn', {
      class: {
        disabled: !availability.enabled
      },
      attrs: {
        "outlined": "",
        "block": "",
        "color": availability.enabled ? 'primary' : 'default'
      },
      on: {
        "click": function click($event) {
          return _vm.enableAvailability(availability);
        }
      }
    }, [_vm._v(_vm._s(availability.day))])], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "sm": "7",
        "md": "2"
      }
    }, [_c('vx-input', {
      staticClass: "mt-2",
      attrs: {
        "type": "checkbox",
        "label": "24 hours",
        "disabled": !availability.enabled
      },
      on: {
        "change": function change($event) {
          return _vm.space.setTwenty4by7();
        }
      },
      model: {
        value: availability.all,
        callback: function callback($$v) {
          _vm.$set(availability, "all", $$v);
        },
        expression: "availability.all"
      }
    })], 1), _vm.breakpoint.sm ? _c('v-col', {
      attrs: {
        "sm": "1"
      }
    }, [_c('vx-btn', {
      attrs: {
        "icon": "",
        "tooltip": "Copy these times to all other selected days",
        "disabled": !availability.enabled
      },
      on: {
        "click": function click($event) {
          return _vm.space.copyToAll(availability);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-content-copy")])], 1)], 1) : _vm._e(), _c('v-col', {
      class: {
        disabled: !availability.enabled
      },
      attrs: {
        "cols": "12",
        "sm": "12",
        "md": "6"
      }
    }, _vm._l(availability.slots, function (slot, slotIndex) {
      return _c('v-row', {
        key: slotIndex
      }, [_c('v-col', {
        staticClass: "pb-0",
        attrs: {
          "cols": "5",
          "sm": "5",
          "md": "5"
        }
      }, [_c('vx-input', {
        attrs: {
          "type": "select",
          "label": "Start time",
          "rules": {
            required: !availability.all && availability.enabled
          },
          "items": slot._startHours,
          "disabled": availability.all || !availability.enabled
        },
        on: {
          "change": function change($event) {
            return _vm.space.setEndHours(slot);
          }
        },
        model: {
          value: slot.startHour,
          callback: function callback($$v) {
            _vm.$set(slot, "startHour", $$v);
          },
          expression: "slot.startHour"
        }
      })], 1), _c('v-col', {
        staticClass: "pb-0",
        attrs: {
          "cols": "5",
          "sm": "5",
          "md": "5"
        }
      }, [_c('vx-input', {
        attrs: {
          "type": "select",
          "label": "End time",
          "rules": {
            required: !availability.all && availability.enabled,
            availability: {
              index: index,
              slotIndex: slotIndex,
              Space: _vm.space
            }
          },
          "items": slot._endHours,
          "disabled": availability.all || !availability.enabled || !(slot.startHour || slot.startHour == 0)
        },
        model: {
          value: slot.endHour,
          callback: function callback($$v) {
            _vm.$set(slot, "endHour", $$v);
          },
          expression: "slot.endHour"
        }
      })], 1), availability.slots.length > 1 ? _c('v-col', {
        staticClass: "px-0 pb-0",
        attrs: {
          "cols": "2",
          "sm": "1",
          "md": "1"
        }
      }, [_c('vx-btn', {
        attrs: {
          "icon": "",
          "disabled": availability.all || !availability.enabled
        },
        on: {
          "click": function click($event) {
            return _vm.space.removeSlot(index, slotIndex);
          }
        }
      }, [_c('vx-icon', [_vm._v("mdi-delete")])], 1)], 1) : _vm._e(), availability.slots.length == slotIndex + 1 ? _c('v-col', {
        staticClass: "px-sm-0 pb-0",
        attrs: {
          "cols": "10",
          "sm": "1",
          "md": "1"
        }
      }, [_c('vx-btn', {
        attrs: {
          "icon": _vm.breakpoint.smAndUp,
          "block": _vm.breakpoint.xs,
          "small": _vm.breakpoint.xs,
          "disabled": availability.all || !availability.enabled
        },
        on: {
          "click": function click($event) {
            return _vm.space.addSlot(index);
          }
        }
      }, [_c('vx-icon', [_vm._v("mdi-plus")])], 1)], 1) : _vm._e(), _vm.breakpoint.xs && availability.slots.length == slotIndex + 1 ? _c('v-col', {
        attrs: {
          "cols": "2"
        }
      }, [_c('vx-btn', {
        attrs: {
          "icon": "",
          "small": "",
          "tooltip": "Copy these times to all other selected days",
          "disabled": !availability.enabled
        },
        on: {
          "click": function click($event) {
            return _vm.space.copyToAll(availability);
          }
        }
      }, [_c('vx-icon', [_vm._v("mdi-content-copy")])], 1)], 1) : _vm._e()], 1);
    }), 1), _vm.breakpoint.mdAndUp ? _c('v-col', {
      attrs: {
        "md": "1"
      }
    }, [_vm.breakpoint.smAndUp ? _c('vx-btn', {
      attrs: {
        "icon": "",
        "tooltip": "Copy these times to all other selected days",
        "disabled": !availability.enabled
      },
      on: {
        "click": function click($event) {
          return _vm.space.copyToAll(availability);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-content-copy")])], 1) : _vm._e()], 1) : _vm._e()], 1);
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "h-100",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "py-1 font-bold text-subtitle-1"
  }, [_vm._v("Open date")]), _c('v-card-text', {
    staticClass: "pb-2"
  }, [_c('v-radio-group', {
    attrs: {
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.space.covid.spaceStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.space.covid, "spaceStatus", $$v);
      },
      expression: "space.covid.spaceStatus"
    }
  }, [_c('v-row', _vm._l(_vm.OPEN_DATE_OPTIONS.filter(function (option) {
    return [_vm.OPEN_DATE_VALUES.OPEN, _vm.OPEN_DATE_VALUES.SPECIFIC_DATE].includes(option.value);
  }).reverse(), function (item, index) {
    return _c('v-col', {
      key: index,
      staticClass: "py-1",
      attrs: {
        "cols": "12",
        "sm": "6",
        "md": "6"
      }
    }, [_c('v-radio', {
      attrs: {
        "label": item.text,
        "value": item.value
      }
    })], 1);
  }), 1)], 1)], 1), _vm.space.covid.spaceStatus == 'specific-date' ? _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "date",
      "name": "Date",
      "rules": {
        required: true
      }
    },
    model: {
      value: _vm.space.covid.opensAt,
      callback: function callback($$v) {
        _vm.$set(_vm.space.covid, "opensAt", $$v);
      },
      expression: "space.covid.opensAt"
    }
  })], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1)], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }