<template>
  <div class="w-100">
    <v-draggable
      class="w-100"
      :disabled="breakpoint.xs"
      :list="location._information"
      handle=".handle"
      @change="$emit('autoSave')"
    >
      <div v-for="(item, index) in location._information" :key="index">
        <v-card outlined class="mb-4">
          <v-card-title>
            <vx-btn v-if="!breakpoint.xs" class="handle" icon>
              <vx-icon>mdi-drag</vx-icon>
            </vx-btn>

            {{ item.title }}

            <v-spacer />

            <vx-btn icon :disabled="index === 0" @click="moveUp(index)">
              <vx-icon>mdi-arrow-up</vx-icon>
            </vx-btn>

            <vx-btn icon :disabled="index === information.length - 1" @click="moveDown(index)">
              <vx-icon>mdi-arrow-down</vx-icon>
            </vx-btn>
          </v-card-title>

          <v-card-subtitle>
            {{ item.description }}
          </v-card-subtitle>

          <v-card-text>
            <vx-input v-model="item.value" type="wysiwyg" />
          </v-card-text>
        </v-card>
      </div>
    </v-draggable>

    <div>
      Think we should add another field here? Let us know at
      <a href="mailto:support@tutti-space.com">support@tutti-space.com</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LocationCreateStories',
  components: {
    vDraggable: () => import('vuedraggable'),
  },
  props: {
    location: { type: Object, required: true },
  },
  data() {
    return {
      information: [
        {
          key: 'moreInfo',
          title: 'More info',
          description: 'Have more information you want everyone to know about your location? Tell the world here.',
          value: '',
        },
        {
          key: 'keyFeature',
          title: `Key features of ${this.location?.name}`,
          description:
            'Does your location have any key features you want to shout about? Like a special kitchen counter top, a stunning garden fountain, a prized piano, or a jaw dropping infinity cove? Make some noise about those here.',
          value: '',
        },
        {
          key: 'history',
          title: `History of ${this.location?.name}`,
          description:
            'Want to share some fascinating history about this location? Is there a story about why it exists or who has used it in the past? People love stories and places with a bit of history so if your location has a story to tell, please share it here.',
          value: '',
        },
      ],
    };
  },
  mounted() {
    const arrayInfo = [];
    if (this.location?.information && this.location?.information?.length) {
      (this.location.information || []).forEach(element => {
        const info = this.information.find(info => Object.hasOwnProperty.call(element, info.key));
        if (info) {
          info.value = element[info.key];
        }
        arrayInfo.push(info);
      });
    }
    this.location._information = arrayInfo.length ? arrayInfo : this.information;
  },
  methods: {
    moveUp(index) {
      this.$moveUp(this.location._information, index);
    },

    moveDown(index) {
      this.$moveDown(this.location._information, index);
    },
  },
};
</script>
