<template>
  <v-card outlined>
    <v-card-text class="mt-4">
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input v-model="space.name" type="text" name="name" label="Name" required />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input v-model="space.slug" type="text" name="slug" label="Slug" required />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="space.bookingVenue"
            type="select"
            label="Can people hire this location exclusively, or each room/space individually? Or both?"
            name="Booking Venue"
            :items="BOOKING_VENUES"
            required
            @change="setDefaultPriceModel()"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="space.isPublicAddress"
            type="select"
            name="Address Privacy"
            label="Display address publicly?"
            :items="YES_NO_ITEMS"
            @change="onChangePublicAddress()"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input v-model="space.floors" type="number" label="No of floors" @change="$emit('autoSave')" />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="space.types"
            type="autocomplete"
            multiple
            item-text="name"
            item-value="_value"
            :items="locationTypes"
            name="types"
            label="Location types"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input v-model="space.account.name" type="text" label="Profile" disabled />
        </v-col>
      </v-row>

      <Address :location="space" />

      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="space.disciplines"
            type="autocomplete"
            multiple
            :items="disciplines"
            item-text="name"
            item-value="_value"
            name="disciplines"
            label="Disciplines"
            @change="onChangeDisciplines()"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="space.activities"
            type="autocomplete"
            multiple
            :items="activities"
            item-text="name"
            item-value="_value"
            name="activities"
            label="Activities"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input v-model="space.commission" type="text" name="commission" label="Marketplace Commission" required />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="space.manualPaymentCommission"
            type="text"
            name="commission"
            label="Partners commission"
            required
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="space.primaryUse"
            type="select"
            :items="primaryUses"
            item-text="name"
            item-value="_value"
            name="primaryUse"
            label="Primary use"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="space.secondaryUse"
            type="select"
            :items="secondaryUses"
            item-text="name"
            item-value="_value"
            name="secondaryUse"
            label="Secondary use"
          />
        </v-col>

        <v-col cols="12" sm="12" class="py-0">
          <quill-editor v-model="space.description" class="mb-4" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-card outlined class="h-100">
            <v-card-title class="py-1 font-bold text-subtitle-1">Preferences</v-card-title>

            <v-card-subtitle class="mt-2 font-bold text-subtitle-1">Booking System</v-card-subtitle>
            <v-card-text>
              <v-switch
                v-model="space.preferences.bookingSystem.hasPrivateService"
                class="mb-4"
                hide-details
                label="Has External Link"
              ></v-switch>
              <v-row dense>
                <v-col class="mb-0 pb-0" cols="12" sm="6" md="6">
                  <vx-input
                    v-model="space.preferences.bookingSystem.btnText"
                    :disabled="!space.preferences?.bookingSystem?.hasPrivateService"
                    type="text"
                    name="btnText"
                    label="Button Text"
                  />
                </v-col>
                <v-col class="mb-0 pb-0" cols="12" sm="6" md="6">
                  <vx-input
                    v-model="space.preferences.bookingSystem.redirectUrl"
                    :disabled="!space.preferences?.bookingSystem?.hasPrivateService"
                    type="text"
                    name="redirectUrl"
                    label="Redirect URL"
                  />
                </v-col>
                <v-col class="mb-0 pb-0" cols="12" sm="6" md="6">
                  <vx-input
                    v-model="space.preferences.bookingSystem.btnColor"
                    :disabled="!space.preferences?.bookingSystem?.hasPrivateService"
                    type="color"
                    name="btnColor"
                    label="Button color"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-subtitle class="py-0 font-bold text-subtitle-1">Seo</v-card-subtitle>
            <v-card-text>
              <v-switch v-model="space.seo.noindex" hide-details label="No index"></v-switch>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { FeatureMixin } from '@tutti/mixins';
import { ACCOUNT_TYPES, USER_TYPES, BOOKING_VENUES, YES_NO_ITEMS } from '@tutti/constants';

import Address from './address';

export default {
  components: {
    Address,
  },
  mixins: [FeatureMixin],
  props: {
    space: { type: Object, required: true },
    accounts: { type: Array, required: true },
  },
  data() {
    return {
      USER_TYPES,
      ACCOUNT_TYPES,
      BOOKING_VENUES,
      YES_NO_ITEMS,
      primaryUses: [],
      secondaryUses: [],
    };
  },
  created() {
    this.$setFeatures({
      disciplines: true,
      activities: true,
      locationTypes: true,
    });

    this.setPrimaryAndSecondaryUses();
  },

  methods: {
    onChangePublicAddress() {
      this.space.setGeoLocation();
      this.$emit('autoSave');
    },

    setDefaultPriceModel() {
      if (!this.space.priceModel && ['location', 'both'].includes(this.space.bookingVenue)) {
        this.space.priceModel = 'quote';
      }
      this.$emit('autoSave');
    },

    onChangeDisciplines() {
      this.setPrimaryAndSecondaryUses();
      this.resetPrimaryAndSecondaryUse();
    },

    setPrimaryAndSecondaryUses() {
      this.primaryUses = this.space.disciplines.map(x => this.disciplines.find(y => y._id === x.attributeId));
      this.secondaryUses = this.space.disciplines.map(x => this.disciplines.find(y => y._id === x.attributeId));
    },

    resetPrimaryAndSecondaryUse() {
      if (!this.primaryUses.some(primary => primary._id === this.space.primaryUse?.attributeId)) {
        this.space.primaryUse = {};
      }

      if (!this.secondaryUses.some(secondary => secondary._id === this.space.secondaryUse?.attributeId)) {
        this.space.secondaryUse = {};
      }
    },
  },
};
</script>
