var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-form', {
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "label": "Enter postcode",
      "disabled": _vm.hasLocation,
      "name": "Postcode",
      "placeholder": "ex. ID1 1QD",
      "append-outer-icon": "mdi-magnify",
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.setLocation();
      },
      "click:append-outer": function clickAppendOuter($event) {
        return _vm.getLocations();
      }
    },
    model: {
      value: _vm.postcode,
      callback: function callback($$v) {
        _vm.postcode = $$v;
      },
      expression: "postcode"
    }
  }), _c('vx-input', {
    attrs: {
      "type": "select",
      "label": _vm.locations.length ? "Please select your address from ".concat(_vm.locations.length, " locations") : 'Please select your address',
      "name": "Location",
      "items": _vm.locations,
      "item-value": "udprn",
      "required": ""
    },
    on: {
      "change": _vm.setAddress
    },
    model: {
      value: _vm.udprn,
      callback: function callback($$v) {
        _vm.udprn = $$v;
      },
      expression: "udprn"
    }
  }), _c('vx-input', {
    attrs: {
      "type": "number",
      "label": "Enter radius(In meter)",
      "name": "radius",
      "placeholder": "ex. 200",
      "required": ""
    },
    model: {
      value: _vm.location.radius,
      callback: function callback($$v) {
        _vm.$set(_vm.location, "radius", $$v);
      },
      expression: "location.radius"
    }
  })], 1), _vm.mounted ? _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "6"
    }
  }, [_vm.mounted ? _c('what3words-autosuggest', {
    staticClass: "w-100 mb-6",
    attrs: {
      "id": "autosuggest",
      "value": _vm.words,
      "clip-to-country": "GB",
      "placeholder": "daring.lion.race"
    },
    on: {
      "change": function change($event) {
        return _vm.checkWhat3Words($event);
      }
    }
  }) : _vm._e(), _c('vx-input', {
    attrs: {
      "type": "text",
      "readonly": "",
      "label": "Nearest place",
      "disabled": _vm.hasLocation,
      "name": "Postcode"
    },
    model: {
      value: _vm.nearestPlace,
      callback: function callback($$v) {
        _vm.nearestPlace = $$v;
      },
      expression: "nearestPlace"
    }
  })], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }