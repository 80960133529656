<template>
  <v-card outlined class="mb-4">
    <v-card-title class="font-bold text-subtitle-1">{{ feature.name }}</v-card-title>
    <v-card-text>
      <v-row>
        <template v-for="(attribute, index) in feature.attributes">
          <v-col
            v-if="attribute.hasChild || !attribute.parentId"
            :key="index"
            :cols="getBreakpoint(attribute)"
            :sm="getBreakpoint(attribute)"
            :md="getBreakpoint(attribute)"
            class="py-1"
          >
            <div v-if="attribute.hasChild">
              <span class="font-bold text-subtitle-1">{{ attribute.name }}</span>
              <v-row>
                <v-col
                  v-for="(attribute, index) in feature.child[attribute._id]"
                  :key="index"
                  cols="6"
                  sm="6"
                  md="6"
                  class="py-1"
                >
                  <div v-if="!filter && attribute.fieldType == 'number'" class="pt-1">
                    <vx-input
                      v-model="attributes[attribute._id].value"
                      type="number"
                      :label="attribute.name"
                      :default="0"
                      hide-details
                    />
                  </div>
                  <div v-else-if="!filter && attribute.fieldType == 'text'" class="pt-1">
                    <vx-input
                      v-model="attributes[attribute._id].value"
                      type="text"
                      :label="attribute.name"
                      hide-details
                    />
                  </div>
                  <v-checkbox
                    v-else
                    v-model="attributes[attribute._id].value"
                    :label="attribute.name"
                    hide-details
                    dense
                  ></v-checkbox>
                </v-col>
              </v-row>
            </div>

            <div v-else-if="!attribute.parentId">
              <div v-if="!filter && attribute.fieldType == 'text'" class="pt-1">
                <vx-input v-model="attributes[attribute._id].value" type="text" :label="attribute.name" hide-details />
              </div>

              <div v-else-if="!filter && attribute.fieldType == 'textarea'" class="pt-1">
                <textarea-field v-model="attributes[attribute._id].value" :label="attribute.name" hide-details />
              </div>

              <div v-else-if="!filter && attribute.fieldType == 'number'" class="pt-1">
                <vx-input
                  v-model="attributes[attribute._id].value"
                  type="number"
                  :label="attribute.name"
                  :default="0"
                  hide-details
                />
              </div>
              <div v-else-if="!filter && attribute.fieldType == 'wysiwyg'" class="pt-1">
                <span class="font-bold text-subtitle-1">{{ attribute.name }}</span>
                <!-- <wysiwyg-field
                  v-model="attributes[attribute._id].value"
                  :placeholder="attribute.placeholder"
                  hide-details
                /> -->

                <quill-editor v-model="attributes[attribute._id].value" :placeholder="attribute.placeholder">
                </quill-editor>
              </div>
              <v-checkbox
                v-else
                v-model="attributes[attribute._id].value"
                :label="attribute.name"
                hide-details
                dense
              ></v-checkbox>
            </div>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SpaceFeature',
  props: {
    space: { type: Object, required: false },
    feature: { type: Object, required: true },
    attributes: { type: Object, default: () => ({}) },
    filter: { type: Boolean, default: false },
  },
  methods: {
    getBreakpoint(attribute) {
      if (
        this.feature.slug == 'precautions_taken' ||
        this.feature.attributes.length == 1 ||
        attribute.hasChild ||
        attribute.fieldType == 'text' ||
        attribute.fieldType == 'wysiwyg'
      ) {
        return 12;
      } else {
        return 6;
      }
    },
  },
};
</script>
