<template>
  <v-card outlined>
    <v-card-text>
      <v-row>
        <v-col cols="12" lg="10">
          <div class="mb-4">
            <b>My space is available on...</b>
            <br v-if="breakpoint.xs" />(Click a day to make it available)

            <vx-input
              v-model="space._twenty4by7"
              type="checkbox"
              class="mt-4"
              label="We’re open 24/7"
              @change="space.setAvailabilities24x7()"
            />
          </div>

          <v-row v-for="(availability, index) in space.availabilities" :key="index">
            <v-col v-if="breakpoint.smAndDown" cols="12">
              <v-divider />
            </v-col>

            <v-col cols="12" sm="4" md="3">
              <vx-btn
                outlined
                block
                :color="availability.enabled ? 'primary' : 'default'"
                :class="{ disabled: !availability.enabled }"
                @click="enableAvailability(availability)"
                >{{ availability.day }}</vx-btn
              >
            </v-col>

            <v-col cols="12" sm="7" md="2">
              <vx-input
                v-model="availability.all"
                type="checkbox"
                label="24 hours"
                class="mt-2"
                :disabled="!availability.enabled"
                @change="space.setTwenty4by7()"
              />
            </v-col>

            <v-col v-if="breakpoint.sm" sm="1">
              <vx-btn
                icon
                tooltip="Copy these times to all other selected days"
                :disabled="!availability.enabled"
                @click="space.copyToAll(availability)"
              >
                <vx-icon>mdi-content-copy</vx-icon>
              </vx-btn>
            </v-col>

            <v-col cols="12" sm="12" md="6" :class="{ disabled: !availability.enabled }">
              <v-row v-for="(slot, slotIndex) in availability.slots" :key="slotIndex">
                <v-col cols="5" sm="5" md="5" class="pb-0">
                  <vx-input
                    v-model="slot.startHour"
                    type="select"
                    label="Start time"
                    :rules="{ required: !availability.all && availability.enabled }"
                    :items="slot._startHours"
                    :disabled="availability.all || !availability.enabled"
                    @change="space.setEndHours(slot)"
                  />
                </v-col>

                <v-col cols="5" sm="5" md="5" class="pb-0">
                  <vx-input
                    v-model="slot.endHour"
                    type="select"
                    label="End time"
                    :rules="{
                      required: !availability.all && availability.enabled,
                      availability: { index, slotIndex, Space: space },
                    }"
                    :items="slot._endHours"
                    :disabled="availability.all || !availability.enabled || !(slot.startHour || slot.startHour == 0)"
                  />
                </v-col>

                <v-col v-if="availability.slots.length > 1" cols="2" sm="1" md="1" class="px-0 pb-0">
                  <vx-btn
                    icon
                    :disabled="availability.all || !availability.enabled"
                    @click="space.removeSlot(index, slotIndex)"
                  >
                    <vx-icon>mdi-delete</vx-icon>
                  </vx-btn>
                </v-col>

                <v-col v-if="availability.slots.length == slotIndex + 1" cols="10" sm="1" md="1" class="px-sm-0 pb-0">
                  <vx-btn
                    :icon="breakpoint.smAndUp"
                    :block="breakpoint.xs"
                    :small="breakpoint.xs"
                    :disabled="availability.all || !availability.enabled"
                    @click="space.addSlot(index)"
                  >
                    <vx-icon>mdi-plus</vx-icon>
                  </vx-btn>
                </v-col>

                <v-col v-if="breakpoint.xs && availability.slots.length == slotIndex + 1" cols="2">
                  <vx-btn
                    icon
                    small
                    tooltip="Copy these times to all other selected days"
                    :disabled="!availability.enabled"
                    @click="space.copyToAll(availability)"
                  >
                    <vx-icon>mdi-content-copy</vx-icon>
                  </vx-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col v-if="breakpoint.mdAndUp" md="1">
              <vx-btn
                v-if="breakpoint.smAndUp"
                icon
                tooltip="Copy these times to all other selected days"
                :disabled="!availability.enabled"
                @click="space.copyToAll(availability)"
              >
                <vx-icon>mdi-content-copy</vx-icon>
              </vx-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card outlined class="h-100">
                <v-card-title class="py-1 font-bold text-subtitle-1">Open date</v-card-title>
                <v-card-text class="pb-2">
                  <v-radio-group v-model="space.covid.spaceStatus" dense required>
                    <v-row>
                      <v-col
                        v-for="(item, index) in OPEN_DATE_OPTIONS.filter(option =>
                          [OPEN_DATE_VALUES.OPEN, OPEN_DATE_VALUES.SPECIFIC_DATE].includes(option.value)
                        ).reverse()"
                        :key="index"
                        cols="12"
                        sm="6"
                        md="6"
                        class="py-1"
                      >
                        <v-radio :label="item.text" :value="item.value"></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-card-text>

                <v-card-text v-if="space.covid.spaceStatus == 'specific-date'" class="py-0">
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <vx-input v-model="space.covid.opensAt" type="date" name="Date" :rules="{ required: true }" />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { PRICE_MODELS, OPEN_DATE_VALUES, OPEN_DATE_OPTIONS } from '@tutti/constants';

export default {
  props: {
    space: { type: Object, required: true },
  },
  data() {
    return {
      PRICE_MODELS,
      OPEN_DATE_VALUES,
      OPEN_DATE_OPTIONS,
    };
  },
  methods: {
    enableAvailability(availability) {
      this.touched = true;
      availability.enabled = !availability.enabled;
      this.space.setTwenty4by7();
    },
  },
};
</script>
