var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": _vm.filter ? 12 : 6,
      "md": "6"
    }
  }, [_vm._t("left"), _vm._l(_vm.features, function (feature, index) {
    return [feature.sort % 2 != 0 && !feature.hideFrom.create ? _c('SpaceFeature', {
      key: index,
      attrs: {
        "space": _vm.space,
        "filter": _vm.filter,
        "feature": feature,
        "attributes": _vm.attributes
      }
    }) : _vm._e()];
  })], 2), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": _vm.filter ? 12 : 6,
      "md": "6"
    }
  }, [_vm._l(_vm.features, function (feature, index) {
    return [feature.sort % 2 == 0 && !feature.hideFrom.create ? _c('SpaceFeature', {
      key: index,
      attrs: {
        "space": _vm.space,
        "filter": _vm.filter,
        "feature": feature,
        "attributes": _vm.attributes
      }
    }) : _vm._e()];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }